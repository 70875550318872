export default ({ app }, inject) => {
    inject('getOrderedList', getOrderedList);
    inject('getCreditCheckString', getCreditCheckString);
    inject('getDirectPaymentToBanksString', getDirectPaymentToBanksString);
    inject('removeParagraphs', removeParagraphs);
    inject('formatId', formatId);

    inject('isNorway', isNorway);
    inject('isSweden', isSweden);
    inject('isSpain', isSpain);
    inject('isEstonia', isEstonia);
    inject('isDenmark', isDenmark);


    inject('formatBoolean', (boolean) => {
        return boolean ? app.$translate('yes', 'Ja') : app.$translate('no', 'Nej');
    });

    inject('validateText', (textElement) => {
        if (typeof(textElement) === 'string') {
            return Boolean(textElement?.trim());
        }
        return Boolean(app.$prismic.asText(textElement)?.trim());
    });

    inject('getSpanishLoanDuration', getSpanishLoanDuration);

    inject('getDocumentPreamble', (document) => {
        let preamble = document.data.preamble;
        if (app.$validateText(preamble)) {
            return preamble;
        }
        document.data.body
            .some(slice => slice.slice_type === 'content' && slice.primary.content
                .some(content => {
                    if (content.type === 'paragraph') {
                        preamble = content.text;
                        return true;
                    }
                    return false;
                })
            );
        return preamble;
    });
};

// TODO: Move functionality to core-models
const getSpanishLoanDuration = (model, monthShort, yearShort) => {

    let minDuration = model.minDuration;
    let maxDuration = model.maxDuration;

    if (maxDuration <= 6) {

        minDuration = minDuration === 1 ? 2 : minDuration;

        if (minDuration === maxDuration) {
            return `${minDuration * 30 + 1} días`;
        }

        if (maxDuration <= 2) {
            return `${minDuration * 30 + 1}+ días`;
        }

        return `${minDuration * 30 + 1} - ${maxDuration * 30} días`;
    }

    if (minDuration < 3) {
        // eslint-disable-next-line camelcase
        model._data.duration_interval_min = 3;
    }

    return model.getDurationString(monthShort, yearShort);
};

const isNorway = () => {
    return process.env.PRISMIC_LOCALE === 'no';
};

const isSweden = () => {
    return process.env.PRISMIC_LOCALE === 'sv-se';
};

const isSpain = () => {
    return process.env.PRISMIC_LOCALE === 'es-es';
};

const isEstonia = () => {
    return process.env.PRISMIC_LOCALE === 'et-ee';
};

const isDenmark = () => {
    return process.env.PRISMIC_LOCALE === 'da-dk';
};

const removeParagraphs = (html) => {
    return html.replace(/<p>|<\/p>/g, '');
    // return html;
};

const formatId = (id) => {
    return id.replace('$', '-');
};

const getCreditCheckString = (creditCheck) => {
    return {
        'creditsafe': 'Creditsafe',
        'bisnode': 'Bisnode',
        'safenode': 'Safenode',
        'uc': 'UC'
    }[creditCheck] || 'unknown';
};

const getDirectPaymentToBanksString = (model) => {
    const banks = {
        'hasDirectPaymentToDanskeBank': 'DanskeBank',
        'hasDirectPaymentToHandelsbanken': 'Handelsbanken',
        'hasDirectPaymentToNordea': 'Nordea',
        'hasDirectPaymentToSEB': 'SEB',
        'hasDirectPaymentToSwedbank': 'Swedbank',
    };
    return Object.entries(banks)
        .filter(([key]) => model[key])
        .map(([,value]) => value)
        .join(', ');
};

const getOrderedList = (list, allLenders, modelClass) => {

    let arr = []; 
    let maxSalesCount = 0;
    let maxConversionRate = 0;
    let maxEpc = 0;

    list.forEach(l => {
        const lender = Object.assign({}, allLenders[l.id]);
        lender.model = new modelClass(lender);

        maxEpc = lender.model._core.sales_stats.epc > maxEpc ? lender.model._core.sales_stats.epc : maxEpc;
        maxSalesCount = lender.model._core.sales_stats.sales > maxSalesCount ? lender.model._core.sales_stats.sales : maxSalesCount;
        maxConversionRate = lender.model._core.sales_stats.conversion_rate > maxConversionRate ? lender.model._core.sales_stats.conversion_rate : maxConversionRate;

        arr.push(lender);
    });

    arr.forEach(lender => {

        if (lender.model._core.sales_stats.sales === 0) {
            lender.order = lender.model._core.sales_stats.clicks * -1;
        }
        else if (lender.model._core.sales_stats.sales < 5) {
            lender.order =
                lender.model._core.sales_stats.epc / maxEpc +
                lender.model._core.sales_stats.sales / maxSalesCount +
                lender.model._core.sales_stats.conversion_rate / maxConversionRate;
        }
        else {
            lender.order =
                (8 * (lender.model._core.sales_stats.epc / maxEpc)) +
                lender.model._core.sales_stats.sales / maxSalesCount +
                lender.model._core.sales_stats.conversion_rate / maxConversionRate;
        }
    });

    return arr.slice(0).sort((a, b) => b.order - a.order);
};